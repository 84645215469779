<template>
  <v-dialog 
    v-model="dialog"
    max-width="650"
    scrollable
  >
    <close-button @click="closeDialog()" overflow />

    <v-card>
      <v-card-title class="font-weight-bold gap-20" dense>
        <span>Recipes</span>
        
        <v-text-field
          v-model="search"
          v-if="!getting && recipes.length"
          class="font-weight-medium"
          placeholder="Filter foods"
          hide-details
          clearable
          outlined
          rounded
          dense
        ></v-text-field>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <preloader v-if="getting" />

        <v-alert v-if="!getting && !recipes.length" class="my-5" type="info" border="left">
          No recipes found
        </v-alert>

        <div v-if="!getting && recipes.length" class="pt-5">
          <div>{{ recipes.length }} recipes found</div>

          <v-list dense>
            <v-list-item
              v-for="recipe in confRecipes"
              :key="recipe.id"
              :to="{ name: 'EditRecipe', params: { id: recipe.id } }"
              target="_blank"
            >
              <v-list-item-title>{{ recipe.name }}</v-list-item-title>
              <v-list-item-icon>
                <v-icon small>mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import db from '@/firebase/init'
import { orderBy } from 'lodash'

export default {
  props: {
    id: String
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      recipes: [],
      search: null,
      dialog: false,
      getting: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    confRecipes: function () {
      let recipes = this.recipes.filter(recipe => {
        if (!this.search) return true
        else return recipe.name.toLowerCase().includes(this.search.toLowerCase())
      })

      return orderBy(recipes, 'name', 'asc')
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    getRecipes(food) {
      this.getting = true
      
      db.collectionGroup('ingredients')
      .where('food', '==', food.id)
      .get()
      .then((snapshot) => {
        if (snapshot.size) {
          let promises = []
          
          snapshot.forEach(food => {
            promises.push(db.collection('recipes').doc(food.ref.parent.parent.id).get())
          })

          Promise.all(promises)
          .then(recipesCollection => {
            recipesCollection.forEach(doc => {
              if (doc.exists && !this.recipes.find(r => r.id == doc.id)) {
                let recipe = doc.data()
                recipe.ref = doc.ref
                recipe.id = doc.id
                this.recipes.push(recipe)
              }
            })

            this.getting = false
          })
        }
        else {
          this.getting = false
        }
      })
      .catch((error) => {
        console.log(error.message)
        this.getting = false
      })
    },

    closeDialog() {
      this.dialog = false
    },

    showDialog(id) {
      this.recipes = []
      this.getting = false
      this.dialog = true
      this.getRecipes(id)
    }
  }

}
</script>