<template>
  <v-dialog
    v-model="dialog"
    overlay-color="primary"
    overlay-opacity="0.9"
    max-width="750"
    class="overflow-visible"
  >
    <close-button @click="closeDialog()" overflow />

    <v-card>
      <v-card-title>
        <div class="pr-10 font-weight-bold subtitle-2" :style="{ lineHeight: '1.2' }">{{ data.name }}</div>
      </v-card-title>
      <v-card-text class="pt-3">
        <preloader v-if="getting" />

        <v-alert v-else-if="!getting && !portions.length" type="info" border="left" text>
          No portions found
        </v-alert>

        <v-card class="mb-5" v-else outlined>
          <v-simple-table dense>
            <template #default>
              <thead>
                <tr>
                  <th>Name</th>
                  <th class="text-center">Unit</th>
                  <th >Weight</th>
                  <th colspan="2">Default</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="portion in portions" :key="portion.id">
                  <td width="40%">
                    <v-text-field
                      v-model="portion.name"
                      hide-details
                      dense
                    ></v-text-field>
                  </td>
                  <td class="text-center" width="20%">{{ portion.unit }}</td>
                  <td>
                    <v-text-field
                      v-model="portion.weight"
                      hide-details
                      dense
                    ></v-text-field>
                  </td>
                  <td class="text-center">
                    <v-switch
                      v-model="defaultMeasurement"
                      :value="portion.name"
                      :disabled="status.updatingDefault"
                      :loading="status.updatingDefault"
                      @change="setDefaultPortion(portion.name, portion.weight)"
                    >
                    </v-switch>
                  </td>
                  <td width="15">
                    <v-btn
                      @click="deletePortion(portion)"
                      :loading="deleting == portion.id"
                      :disabled="deleting && deleting !== portion.id"
                      small
                      icon
                    >
                      <v-icon color="error" x-small>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>

        <div class="d-flex justify-space-between align-center">
          <div>
            <v-btn 
              v-if="portions.length"
              @click="savePortions()"
              :loading="saving"
              color="primary" 
              class="mr-2"
              depressed
            >Save Changes</v-btn>

            <v-btn
              @click="addPortion()"
              :loading="adding"
              depressed
            >Add New</v-btn>
          </div>
            <v-btn
              color="error"
              :disabled="!defaultMeasurement"
              @click="setDefaultPortion('', '', true)"
              :loading="status.updatingDefault"
              depressed
            >Remove Default</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from '@/rules'
import { mapState } from 'vuex'
import db from '@/firebase/init'
import { omit } from 'lodash'

export default {
  props: {
    food: {
      type: Object,
      required: true,
    }
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      data: {},
      portions: [],
      dialog: false,
      saving: false,
      adding: false,
      getting: false,
      deleting: false,
      defaultMeasurement: '',
      defaultWeight: ''
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: state => state.foods.status
    })
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'food': {
      handler(value) {
        if (Object.keys(value).length) {
          this.data = {}
          Object.assign(this.data, value)

          if(value?.defaultMeasurement)
            this.defaultMeasurement = value.defaultMeasurement

          this.getPortions()
        }
      },
      immediate: true
    },
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    closeDialog() {
      this.dialog = false
    },

    /*------------------------------------------------------------------------------
     * GET PORTIONS
     *----------------------------------------------------------------------------*/
    getPortions() {
      this.getting = true
      this.portions = []

      if (this.data) {
        this.data.ref
        .collection('portions')
        .get()
        .then(snapshot => {
          if (snapshot.size) {
            snapshot.forEach(doc => {
              let portion = doc.data()
              portion.id = doc.id
              portion.ref = doc.ref
              this.portions.push(portion)
            })
          }
          
          this.getting = false
        })
        .catch(error => {
          this.getting = false
          console.log(error.message)
        })
      }
    },

    /*------------------------------------------------------------------------------
     * SAVE PORTIONS
     *----------------------------------------------------------------------------*/
    savePortions() {
      this.saving = true
      let batch = db.batch()

      this.portions.forEach(portion => {
        batch.set(portion.ref, omit(portion, ['id', 'ref']))
      })

      batch.commit()
      .then(() => {
        this.saving = false
        this.$store.dispatch('showSuccess', 'Portions updated. Food is now being reviewed again')

        // If food is already pending
        if(this.food.status === 'pending')
          return

        // Set the food to pending
        Promise.resolve(
          this.$store.dispatch('foods/updateFoodField', {
            food: this.food,
            field: 'status',
            value: 'pending',
            silent: true
          })
        )
        .then(() => {
          this.$store.commit('foods/addToPendingFoods', this.food)
          this.$store.commit('foods/removeFood', this.food)
        })

      })
      .catch(error => {
        this.saving = false
        console.log(error.message)
        this.$store.dispatch('showError', error.message)
      })
    },

    /*------------------------------------------------------------------------------
     * ADD PORTION
     *----------------------------------------------------------------------------*/
    addPortion() {
      this.adding = true
      let portionData = { name: null,  unit: 'g', weight: 0 }

      this.data.ref
      .collection('portions')
      .add(portionData)
      .then((docRef) => {
        portionData.ref = docRef
        portionData.id = docRef.id
        this.portions.push(portionData)
        this.adding = false
      })
      .catch(error => {
        this.adding = false
        console.log(error.message)
        this.$store.dispatch('showError', error.message)
      })
    },

    /*------------------------------------------------------------------------------
     * DELETE PORTION
     *----------------------------------------------------------------------------*/
    deletePortion(portion) {
      this.deleting = portion.id

      portion.ref
      .delete()
      .then(() => {
        this.deleting = false
        this.$store.dispatch('showSuccess', 'Portion deleted')
        this.portions.splice(this.portions.indexOf(portion), 1)
      })
      .catch(error => {
        this.deleting = false
        console.log(error.message)
        this.$store.dispatch('showError', error.message)
      })
    },
    /*------------------------------------------------------------------------------
     * SET DEFAULT PORTION
     *----------------------------------------------------------------------------*/
    setDefaultPortion(measurement, weight, remove = false) {
        this.$store.dispatch('foods/updateDefaultMeasurement', { food: this.data, defaultMeasurement: measurement, defaultWeight: weight })

        if(remove) this.defaultMeasurement = ""
    }
  }
}
</script>