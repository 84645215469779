<template>
    <v-dialog
        v-model="dialog"
        overlay-color="primary"
        overlay-opacity="0.9"
        max-width="350"
        class="overflow-visible"
    >
        <close-button @click="closeDialog()" overflow />

        <v-card>
            <v-card-title>
                <div class="pr-10 font-weight-bold subtitle-2" :style="{ lineHeight: '1.2' }">{{ food.name }}</div>
            </v-card-title>
            <v-card-text class="pt-3">
                <v-card outlined>
                    <v-form
                        @submit.prevent="validateForm()"
                        ref="columnForm"
                    >
                        <v-card-text class="pa-5">
                                <v-text-field
                                    v-model="name"
                                    dense
                                    outlined
                                    :rules="[rules.required]"
                                    label="Name"
                                >
                                </v-text-field>
                                <v-text-field
                                    v-model="percentRI"
                                    dense
                                    :rules="[rules.required]"
                                    outlined
                                    label="Percent RI"
                                >
                                </v-text-field>
                                <v-text-field
                                    v-model="unit"
                                    dense
                                    :rules="[rules.required]"
                                    outlined
                                    label="Unit"
                                >
                                </v-text-field>
                                <v-text-field
                                    v-model="val"
                                    :rules="[rules.required]"
                                    dense
                                    outlined
                                    label="Value"
                                >
                                </v-text-field>
                                <v-select
                                    :items="items"
                                    label="Outlined style"
                                    :rules="[rules.required]"
                                    v-model="publish"
                                    outlined
                                ></v-select>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn depressed color="primary" type="submit" :loading="loading" >Submit</v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import rules from '@/rules'
import { mapActions } from 'vuex'
export default {
    name: 'FoodColumn',
    data () {
        return {
            name: "",
            percentRI: "",
            unit: "",
            val: "",
            dialog: false,
            rules,
            loading: false,
            publish: "",
            items: ['publish', 'unpublish'],
        }
    },
    props: {
        food: {
            type: Object,
            required: true
        }
    },
    methods: {
        ...mapActions('foods', ['createColumn']),
        validateForm() {
            if (this.$refs.columnForm.validate()) {
                this.loading = true
                this.createColumn({
                    food: this.food,
                    data: {
                        name: this.name,
                        percentRI: this.percentRI,
                        unit: this.unit,
                        val: this.val,
                        publish: this.publish
                    }
                })
                .then(() => {
                    this.loading = false
                    this.$store.dispatch('showSuccess', 'Column Added')
                    this.closeDialog()
                })
                .catch(() => {
                    this.loading = false
                    this.$store.dispatch('showError', 'Something went wrong')
                })
            }
        },
        closeDialog() {
            this.dialog = false
            this.$refs.columnForm.reset()
            this.$refs.columnForm.resetValidation()
        }
    }
}
</script>