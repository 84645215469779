<template>
  <v-navigation-drawer
    v-model="drawer"
    width="350"
    temporary
    clipped
    fixed
    right
  >
    <v-card flat>
      <v-card-title class="font-weight-bold">
        Categories
      </v-card-title>
      <v-card-text>
        <preloader v-if="status.gettingCategories" />

        <v-card v-if="!status.gettingCategories && categories.length" class="mb-5" outlined>
          <v-list dense>
            <v-list-item
              v-for="category in orderBy(categories, 'name', 'asc')"
              :key="category.id"
            >
              <v-list-item-title>{{ category.name }}</v-list-item-title>
              <v-list-item-action class="my-0">
                <v-menu offset-y left>
                  <template #activator="{ on }">
                    <v-btn
                      v-on="on"
                      small
                      icon
                    >
                      <v-icon small>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="setData(category)">
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="confirmDelete(category)">
                      <v-list-item-title class="error--text">Delete</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>

        <v-form ref="form">
          <v-text-field
            v-model="data.name"
            placeholder="Category name"
            :rules="[rules.required]"
            hide-details
            outlined
            dense
          >
            <v-btn
              @click="validateForm()"
              :loading="status.addingCategory"
              class="mb-2 mr-n1"
              color="accent"
              slot="append"
              depressed
              small
              dark
            >Save</v-btn>
          </v-text-field>
        </v-form>
      </v-card-text>
    </v-card>

   <!-- CONFIRM DELETE -->
  <confirm-delete
    :showDialog="showDelete"
    :message="deleteMessage"
    :deleting="status.deletingCategory"
    @confirmed="deleteConfirmed()"
    @cancel="closeDeleteDialog()"
  />

  </v-navigation-drawer>
</template>

<script>
import rules from '@/rules'
import { orderBy } from 'lodash'
import { mapState, mapActions } from 'vuex'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      orderBy,
      data: {},
      drawer: false,
      toDelete: null,
      showDelete: false,
      deleteMessage: null,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: state => state.foods.status,
      categories: state => state.foods.categories,
    })
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('foods', [
      'saveCategory',
      'deleteCategory'
    ]),

    validateForm() {
      if (this.$refs.form.validate()) {
        Promise.resolve(this.saveCategory(this.data))
        .then(() => {
          this.$refs.form.reset()
          this.$refs.form.resetValidation()
        })
      }
    },

    setData(category) {
      this.data = category
    },

    confirmDelete(category) {
      this.toDelete = category
      this.deleteMessage = `Delete category ${category.name}?`
      this.showDelete = true
    },

    closeDeleteDialog() {
      this.toDelete = null
      this.deleteMessage = null
      this.showDelete = false
    },

    deleteConfirmed() {
      Promise.resolve(this.deleteCategory(this.toDelete))
      .then(() => {
        this.closeDeleteDialog()
      })
    }
  },
}
</script>